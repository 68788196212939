const GetAESKey = (hex: string, algorithm: string) => window.crypto.subtle.importKey(
  'raw',
  Uint8Array.from(Buffer.from(hex, 'hex')).buffer,
  algorithm,
  false,
  ["encrypt", "decrypt"],
);

export const AESGCMEncrypt = async (data: string, keyHex: string, iv: string) => {
  if (!data) return '';

  const algorithm = 'AES-GCM';
  const key = await GetAESKey(keyHex, algorithm);
  const encoded = new TextEncoder().encode(data);

  const arrayBuffer = await window.crypto.subtle.encrypt(
    {
      name: algorithm,
      iv: Uint8Array.from(Buffer.from(iv, 'hex')),
    },
    key,
    encoded,
  );
  return Buffer.from(arrayBuffer).toString('hex');
}

export const AESGCMDecrypt = async (dataHex: string, keyHex: string, iv?: string, encode: BufferEncoding = 'utf8') => {
  if (!dataHex) return '';

  const algorithm = 'AES-GCM';
  const key = await GetAESKey(keyHex, algorithm);

  const arrayBuffer = await window.crypto.subtle.decrypt(
    {
      name: algorithm,
      iv: Uint8Array.from(iv ? Buffer.from(iv, 'hex') : Buffer.alloc(12).fill(0)),
    },
    key,
    Uint8Array.from(Buffer.from(dataHex, 'hex')),
  );
  return Buffer.from(arrayBuffer).toString(encode);
}

export const SHA512 = async (data: string) => {
  const hashBuffer = await window.crypto.subtle.digest("SHA-512", new TextEncoder().encode(data))
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}
