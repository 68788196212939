import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, buildRequest } from '@core/api';
import { AESGCMDecrypt } from '@core/crypto/aes';
import { GenerateKeyPair, GetPublicKey, GetSharedKey } from '@core/crypto/ecdh';
import { Logger } from '@core/log';
import { environment } from '@environments/environment';
import { Observable, firstValueFrom, from } from 'rxjs';

interface HandshakeResponseData {
  PublicKey: string;
  Salt: string;
  EncryptionKey: string;
  IV: string;
}

export type HandshakeData = HandshakeResponseData & { SessionID: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private HandshakeData$!: Observable<HandshakeData>;
  private SessionId!: string;
  private IsHandshaked: boolean = false;

  constructor(private http: HttpClient) { }

  handshake(): (Observable<HandshakeData>) {
    try {
      if (!this.IsHandshaked) {
        this.IsHandshaked = true;
        this.HandshakeData$ = from((async () => {
          const keyPair = await GenerateKeyPair();
          const publicKey = await GetPublicKey(keyPair);
          const data = buildRequest({ PublicKey: publicKey });
          const response = await firstValueFrom(this.http.post<ApiResponse<HandshakeResponseData>>(`${environment.apiUrl}/auth/handshake`, data))
          const sharedKey = await GetSharedKey(keyPair, response.Data.PublicKey);
          const sessionKey = await AESGCMDecrypt(response.Data.EncryptionKey, sharedKey, '', 'hex');
          const salt = await AESGCMDecrypt(response.Data.Salt, sharedKey, '');
          const iv = await AESGCMDecrypt(response.Data.IV, sharedKey, '', 'hex');
          const result = { SessionID: this.GetSessionId(), EncryptionKey: sessionKey, IV: iv, Salt: salt, PublicKey: '' };
          return result;
        })())
      }
    } catch (ex) {
      Logger.log(ex)
      this.HandshakeData$ = from((async () => { return {} as HandshakeData })());
    }
    return this.HandshakeData$;
  }

  SetSessionId(id: string) {
    this.SessionId = id;
  }

  GetSessionId() {
    return this.SessionId;
  }
}
