/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { buildRequest } from '@core/api';
import { AESGCMDecrypt, AESGCMEncrypt, SHA512 } from '@core/crypto/aes';
import { Logger } from '@core/log';
import { Observable, catchError, from, map, mergeMap, of } from 'rxjs';
import { AuthService, HandshakeData } from './auth.service';

const clone = async (request: HttpRequest<any>, handshakeData: HandshakeData) => {
  const isFormData = request.body instanceof FormData;
  const Data = isFormData ? JSON.parse(request.body.get('Data') as string) ?? {} : request.body;
  const body = buildRequest(Data);
  const encryptedBody = await AESGCMEncrypt(JSON.stringify(body), handshakeData.EncryptionKey, handshakeData.IV);
  const Signature = await SHA512(encryptedBody + handshakeData.Salt);

  if (isFormData) request.body.set('Data', encryptedBody);

  const update: any = {
    reportProgress: false,
    setHeaders: {
      'X-Session-ID': handshakeData.SessionID,
      Signature,
    },
    body: isFormData ? request.body : encryptedBody,
    responseType: request.responseType == 'json' ? 'text' : request.responseType,
  };

  Logger.log('Send', request.method, request.url, body);
  return request.clone(update);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const sessionId = request.body.SessionId
    if (sessionId) {
      this.authService.SetSessionId(sessionId);
    }

    if (request.url.endsWith('/auth/handshake')) return next.handle(request.clone({
      setHeaders: {
        'X-Session-ID': this.authService.GetSessionId(),
      },
    }));

    let handshakeData: HandshakeData;

    return this.authService.handshake().pipe(
      mergeMap(async (data) => {
        handshakeData = data;
        request = await clone(request, handshakeData);
      }),
      mergeMap(_ => next.handle(request)),
      mergeMap(async (response: any) => {
        if (response.body && (response.headers as HttpHeaders).get('Content-Type')?.includes('application/json')) {
          response.body = JSON.parse(await AESGCMDecrypt(response.body, handshakeData.EncryptionKey, handshakeData.IV));
        }
        return response;
      }),
      map((response: any) => {
        Logger.log('Receive', request.method, request.url, response.body);
        if (response.body) {
          const headers = response.headers as HttpHeaders
          const contentType = headers.get('Content-Type')
          if (contentType?.includes('application/json')) {
            if (response.body?.ResponseCode !== '200') {
              return from(this.router.navigate(['/result/resultType=NOTFOUND'])).pipe(mergeMap(_ => of(null)));
            }
          } else {
            Logger.log('Receive', request.method, request.url);
          }
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        Logger.log('Error', request.method, request.url, error);
        return from(this.router.navigate(['/result/resultType=NOTFOUND'])).pipe(mergeMap(_ => of(null)));
      })
    )
  }
}
